.form-inline {
    width: 100%;
}
  
.form-group {
    width: 90%;
}

.input-group {
    width: 90% !important;
}

.form-control {
    width: 50% !important;
}

span.input-group-addon {
    width: 50px !important;
}   

.formRowElement {
    display: inline !important;
    width: 25%;
}

.strength-1 {
    color: white;
    opacity: 0.5;
    background-color: red;
}

.strength-2 {
    color: white;
    opacity: 0.5;
    background-color: yellow;
}

.strength-3 {
    color: white;
    opacity: 0.5;
    background-color: blue;
}

.strength-4 {
    color: white;
    opacity: 0.5;
    background-color: green;
}

.strength-5 {
    color: white;
    opacity: 0.5;
    background-color: gray;
}